import { render, staticRenderFns } from "./Permissions.vue?vue&type=template&id=0697dec6&scoped=true"
import script from "./Permissions.vue?vue&type=script&lang=js"
export * from "./Permissions.vue?vue&type=script&lang=js"
import style0 from "./Permissions.vue?vue&type=style&index=0&id=0697dec6&prod&lang=scss"
import style1 from "./Permissions.vue?vue&type=style&index=1&id=0697dec6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0697dec6",
  null
  
)

export default component.exports